html body {
  td.mat-cell.drag,
  td.mat-cell.check {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .app--no-side-gap {
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type,
    th.mat-header-cell:last-of-type {
      padding-right: 0;
    }

    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-header-cell:first-of-type {
      padding-left: 0;
    }
  }

  table.app--fixed-layout {
    table-layout: fixed;
  }

  td.mat-cell.drag {
    cursor: move;
  }

  tr.cdk-drag {
    touch-action: initial !important;
    -webkit-user-drag: initial !important;
    -webkit-tap-highlight-color: initial !important;
    user-select: initial !important;
  }

  th.actions {
    text-align: right;
  }

  td.mat-column-actions {
    white-space: nowrap;
  }

  th.mat-header-cell.drag,
  td.mat-cell.drag,
  th.mat-header-cell.check,
  td.mat-cell.check {
    height: 1px;
    width: 1px;
    padding: 0;
    max-width: 48px;
    min-width: 48px;

    .mat-icon {
      display: block;
      margin: 0 auto;
    }

    .mat-checkbox {
      display: block;
      font-size: 0;
      min-width: 48px;
      width: 100%;
      height: 100%;
    }

    label {
      margin: 0;
      width: 100%;
      height: 100%;
    }

    .mat-checkbox-inner-container {
      margin: auto;
    }

    & + th,
    & + td {
      padding-left: 16px;
    }
  }

  .app--mat-table__sort-container {
    display: flex;
    align-items: center;

    .mat-sort-header {
      margin-right: 8px;
    }
  }
}
