.app--highlight-panel {
  background: #fbd168c7;
  padding: 0 8px;
  border-radius: 4px;
  line-height: 16px;
  font-weight: 600;
  color: #000;

  &.medium {
    line-height: 24px;
    font-size: 14px;
  }

  &.tiny {
    line-height: 12px;
    font-size: 12px;
  }

  &.blue {
    background: #73beff;
  }

  &.red {
    background: #fb5211c7;
  }

  &.no-padding {
    padding: 0;
  }

  &.no-bold {
    font-weight: initial;
  }
}

html body .app__notes-cdk-panel {
  pointer-events: none !important;
}
