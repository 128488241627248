.mat-icon.app--select__select-trigger__svg {
  height: 14px;

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
}

html body .mat-select.app--select--no-arrow {
  .mat-select-arrow-wrapper {
    display: none;
  }

  .mat-select-value {
    max-width: unset;
  }
}

mat-select-trigger mat-icon {
  vertical-align: middle;
}

.mat-select-disabled {
  mat-select-trigger > {
    .mat-icon,
    button {
      color: rgba(0, 0, 0, 0.38);
    }
  }
}

.app--select--search-input {
  outline: none;
  border: none;
  position: sticky;
  top: 0;
  left: 0;
  height: 42px;
  z-index: 1;
  background: #fff;
  padding: 0 16px;
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.2);
}
