@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc9.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype');
}

@font-face {
  font-family: 'Share Tech Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Share Tech Mono'), local('ShareTechMono-Regular'), url(https://fonts.gstatic.com/s/sharetechmono/v9/J7aHnp1uDWRBEqV98dVQztYldFcLowEA.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.cdk-overlay-container {
  z-index: 1031;
}

.fixed-top {
  padding: 5px 16px 0 35px;
  min-height: 70px;
}

.header-select .mat-form-field-label {
  color: rgba(255, 255, 255, .5);
}

.header-select .mat-focused .mat-form-field-ripple {
  background-color: #fff;
}

.header-select .mat-form-field.mat-primary .mat-select-arrow {
  color: #fff;
}

ng-progress[fixed=true][spinnerPosition=right] .ng-spinner {
  display: none;
  position: fixed;
  left: 12px;
  top: 23px;
}

ng-progress[thick=true] .ng-spinner-icon {
  width: 20px !important;
  height: 20px !important;
  border-width: 2px !important;
}

.tab {
  display: none;
}

.tab.active {
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}

.th {
  background: #C4C4C4;
  padding: 10px;
}

.th.dependent {
  background: #e8e8e8;
}

.td {
  display: flex;
  align-items: center;
  padding: 10px;
}

.full-width {
  width: 100%;
}

.rule-item {
  padding-bottom: 20px;
}

.fa.fa-check {
  color: #28a745;
}

.rule-item span, .rule-item strong {
  font-size: 0.95rem;
}

.cursor-pointer {
  cursor: pointer;
}

.col-1094 {
  padding: 0;
}

.col-1094 div.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 0 15px;
  border: 1px solid rgba(34, 36, 38, .1);
}

.col-1094 div.cell.th {
  height: 50px;
}

.col-1094 .mat-form-field-infix {
  width: auto;
  text-align: center;
}

.dependentBenefits .mat-form-field-infix {
  width: 50px;
}
