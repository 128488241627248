html body .mat-dialog-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 -24px -24px -24px;
  height: 60px;
  padding: 8px 24px;
}

html body .mat-dialog-content {
  padding: 24px;
}

html body .mat-dialog-title {
  margin: -24px -24px 0 -24px;
  height: 60px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  background: app-color(primary, 600);
  color: app-color-text(primary, 600);

  .mat-dialog-close {
    margin-left: auto;
    margin-right: -16px;
  }
}

html body .app--dialog--full-screen {
  align-self: flex-end;
  width: 100vw;
  max-width: unset !important;
  height: calc(100vh - 64px);

  .mat-dialog-container {
    padding: 0;
    border-radius: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    transform: translate3d(0, 0, 0);
  }

  &__head {
    height: 54px;
    display: flex;
    align-items: center;
    padding: 0 26px;
    max-width: 1360px;
    margin: 0 auto;

    span {
      margin: 0 auto 0 0;
    }
  }

  &__body {
    height: calc(100% - 76px - 54px);
    padding: 24px;
    max-width: 1360px;
    margin: 0 auto;

    &.no-actions {
      height: calc(100% - 54px);
    }
  }

  &__actions {
    height: 76px;
    display: flex;
    align-items: center;
    padding: 0 26px;
    max-width: 1360px;
    margin: 0 auto;
    padding: 8px;

    & > * {
      padding: 12px;
      border-radius: 10px;
      background: #fff;
    }
  }
}

html body .app--dialog--full-screen-backdrop {
  opacity: 0 !important;
}

html body .app--dialog--compact {
  .mat-dialog-close {
    top: 0;
    right: 0;
    position: absolute;
  }

  .mat-dialog-container {
    position: relative;
  }

  .mat-dialog-content {
    padding: 0 24px;
  }
}

html body .app--dialog--no-padding-vertical {
  .mat-dialog-content {
    padding-top: 0;
    padding-bottom: 0;
  }
}
