@import "projects/shared/styles/settings/mixins";
@import "projects/shared/styles/settings/variables";

.app--text-color {
  &__primary {
    color: app-color(primary, 700);
  }
}

.app--text-disabled {
  opacity: 0.38;
}
