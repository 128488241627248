@import "projects/shared/styles/settings/mixins";
@import "projects/shared/styles/settings/variables";

.mat-icon > svg {
  display: block;
  height: 100%;
  width: 100%;

  &.app--svg__primary {
    stroke: app-color(primary, 600);
    fill: app-color(primary, 600);
  }

  &.smaller {
    padding: 4px;
  }
}

.mat-icon-green {
  color: app-color(common, green-success);
}

.mat-icon-red {
  color: app-color(common, red-error);
}

.mat-icon-default {
  color: app-color(common, gray-disabled);
}
