$page-max-width: 1360px;
$page-base-margin: 0;
$header-height: 64px;
$header-container-height: $page-base-margin + $header-height;
$subheader-height: 48px;
$subheader-margin: 0;
// $subheader-bg: linear-gradient(180deg, rgba(229, 234, 253, 0.85%) 45%, rgba(229, 234, 253, 0.01%) 96%);
// $toolbar-bottom-bg: linear-gradient(360deg, rgba(229, 234, 253, 0.85%) 45%, rgba(229, 234, 253, 0.01%) 96%);
$subheader-bg: transparent;
$toolbar-bottom-bg: transparent;

@mixin forceGpu() {
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
}

html body {
  margin: 0;
}

html body .app--theme__main-background {
  background-color: #d8e7f5;
  height: 100vh;
}

html body .app--elevation,
html body .app--elevation-z1 {
  border: none;
  background: rgba(255, 255, 255, 0.55);
  border-radius: 6px !important;
  // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.05) !important;
  box-shadow: none !important;
  overflow: hidden;
  border: 1px solid #e3e9fd;
}

html body button.app--mat-icon-button-smaller {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

html body .app--ripple-container {
  position: relative;

  .mat-ripple {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

html body .app--elevation-z2 {
  background: rgba(222, 237, 251, 0.65) !important;
  border-radius: 10px !important;
  // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.05) !important;
  box-shadow: none !important;
  overflow: hidden;
}

.app--theme__main-container {
}

.app--theme__main {
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  pointer-events: none;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  &.shown {
    transition: none;
    opacity: 1;
    pointer-events: all;
  }

  img {
    margin-top: 16px;
  }
}

.app--header {
  max-width: $page-max-width;

  //todo: remove duplicate only in project with glass theme
  &__tax-year-select {
    &:empty {
      height: 36px;
    }

    width: 72px;

    mat-form-field {
      width: 100%;
    }

    .mat-form-field-infix {
      border-top: 0;
    }

    .mat-form-field-wrapper {
      padding: 0 !important;
    }

    .mat-select-arrow-wrapper {
      transform: none !important;
    }
  }

  //todo: remove duplicate only in project with glass theme
  &__tax-year-select-wr {
    display: inline-block;
    vertical-align: middle;
    margin-left: 24px;

    label {
      margin-bottom: 0;
      margin-right: 8px;
      background: #2cbe4e;
    }

    .mat-select-value-text,
    .mat-select-arrow {
      color: app-color(primary, 600);
    }
  }

  &__menu-links-container {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 3px;
    white-space: nowrap;
    margin-left: 8px;

    button {
      color: #7784ee;
    }

    button.mat-button.mat-button-disabled {
      color: #b4b7bb;
    }

    button:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__user-menu {
    margin-right: -16px;
    margin-left: auto;

    button {
      outline: none;
    }
  }
}

html body .app--header {
  height: $header-height;
  margin: 0 auto;
  position: relative;
  box-shadow: none;
  background: transparent;
}

app-header {
  display: block;
  z-index: 2;
  margin-top: 0;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.06) !important;
  border-radius: 4px !important;
}

app-header,
.app--theme__main-container {
  position: relative;
}

.app--theme__main-wrapper {
  padding: 0;
  padding-top: 1px;
  margin-top: -1px;
}

.app--header__menu-links-container {
  border: none !important;
  border-radius: 0 !important;
}

.client-list {
  position: absolute !important;
  height: 100vh !important;
  top: 0 !important;
  background: rgba(67, 67, 67, 0.85);
  //backdrop-filter: blur(10px);
  //@include forceGpu();
}

.app--theme__main-container {
  z-index: 1;
  margin: 0 auto;
  background: transparent !important;
  height: calc(100vh - #{$header-container-height});
}

.app--theme__page-stepper {
  max-width: $page-max-width;
  margin: 0 auto;
}

.app--theme__page-stepper--container {
  background: transparent;
}

.app--theme__page-stepper--container .mat-stepper-horizontal {
  background: transparent;
  margin: 0 !important;
}

.app--theme__page--wider,
.app--theme__page--wide {
  height: 100%;

  &__content {
    padding: 0 24px 0;

    &:not(.no-head) {
      padding-top: 48px;
    }
  }

  &__content,
  &__subheader-container {
    &:not(.full-width) {
      max-width: $page-max-width;
      margin: 0 auto;
    }
  }
}

.app--theme__page--wider__content,
.app--theme__page--wide__content,
.app--theme__page-stepper--container {
  & > ng-scrollbar scrollbar-y {
    right: -14px !important;
    bottom: 72px !important;
    top: 48px !important;
  }
}

.app--theme__page--wider__content.fixed,
.app--theme__page--wide__content.fixed {
  height: 100%;
}

.app--theme__page--wider,
.app--theme__page--wide {
  padding: 0;

  &__subheader {
    height: $subheader-height;
    padding: 0;
    margin-bottom: $subheader-margin;
    z-index: 1;
    position: absolute;
    width: 100vw;
    border-radius: 0 !important;
    box-shadow: none !important;
    background: $subheader-bg;
    max-width: $page-max-width;
    left: 50%;
    transform: translateX(-50%);
    padding: 24px;
  }

  &__subheader-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 28px;
    width: 100%;
  }

  &__subheader-actions {
    order: 1;
    pointer-events: all;
  }

  &__title {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.app--theme__bottom-panel {
  background: transparent;
  box-shadow: none;
  height: 48px;
  z-index: 100;
  // background: linear-gradient(360deg, rgba(216, 223, 255, 0.8) 64%, rgba(216, 223, 255, 0.1) 100%);
  background: $toolbar-bottom-bg;
  padding: 8px;
  height: 76px;
  z-index: 1;
  pointer-events: none;

  .app--theme__bottom-panel--container {
    z-index: 100;
    max-width: $page-max-width;
    margin: 0 auto;
    position: relative;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.06) !important;
    border-radius: 10px !important;
    padding: 0 12px !important;
    backdrop-filter: blur(6px);
    transform: translate3d(0, 0, 0);
    pointer-events: all;
    @include forceGpu();
  }

  &:not(.full-width) {
    padding-left: 356px;

    .app--theme__bottom-panel--container {
      max-width: 1004px;
    }
  }
}

.stepper-scroll-container {
  padding-bottom: 64px;
}

.app--theme__page-stepper--left-panel {
  padding: 0;
  border: none;
  border-right: 1px dashed #1e88e5;

  .app--pseudo-validation-stepper__header {
    margin: 0 0 16px 0;
  }
}

.app--theme__page-stepper__subheader-wrapper {
  border-radius: 0 !important;
  box-shadow: none !important;

  position: absolute;
  width: 100vw;
  left: 0;
  z-index: 1;
  background: $subheader-bg;
}

html
  body
  .app--theme__mat-button-link.mat-button.active:not([disabled])::after {
  border-radius: 4px;
}

.app--theme__page-stepper__subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $subheader-height;
  margin-bottom: $subheader-margin;
  z-index: 1;
  position: absolute;
  width: 100vw;
  left: 0;
  z-index: 1;
  background: $subheader-bg;
  padding-left: 356px;
  pointer-events: none;

  &__title {
    margin: 0;
    margin-left: auto;
    color: #4d7292;
    font-weight: 900;
  }

  &-container {
    max-width: $page-max-width - 356px;
    padding: 4px 28px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__subheader-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.app--theme__page-stepper.toolbar-top {
  height: 100%;
  padding-bottom: 0 !important;
}

.mat-form-field-suffix {
  display: flex;
  align-self: start;
}

// Material controls
.mat-form-field-type-app-mat-phone.mat-form-field-appearance-fill,
.mat-form-field-type-mat-chip-list.mat-form-field-appearance-fill,
.mat-form-field-type-mat-select.mat-form-field-appearance-fill,
.mat-form-field-type-mat-input.mat-form-field-appearance-fill,
.mat-form-field-type-mat-date-range-input.mat-form-field-appearance-fill {
  .mat-form-field-flex {
    padding: 0.375em 0.375em 0 0.375em;
    border-radius: 4px;
    border: 1px solid transparent;
    background: rgba(255, 255, 255, 0.4);
    //border-radius: 4px;
    box-shadow: 0 8px 20px 0 rgba(31, 38, 135, 0.06);
  }

  .mat-form-field-infix {
    padding: 0.25em 0 0.375em 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-subscript-wrapper {
    padding: 0 0.5em;
    margin-top: 0.5em;
  }

  .mat-form-field-label {
    color: #1e88e5;
  }

  &.mat-form-field-disabled {
    .mat-form-field-label {
      color: #5c7a95;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 1.125em;
  }

  &.mat-focused {
    .mat-form-field-flex {
      border-color: #77b7ef !important;
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-flex {
      background: #f3caca !important;
    }

    &.mat-focused {
      .mat-form-field-flex {
        border-color: #f39595 !important;
      }
    }
  }
}

html body .text-input-highlight-container .text-highlight-element {
  background: transparent;
}

html body .mat-chip-list-wrapper .mat-chip.mat-standard-chip {
  background: rgba(30, 136, 229, 0.2);
  color: #1e88e5;

  &.warn {
    background-color: rgba(244, 67, 54, 0.2);
    color: #f44336;
  }
}

.mat-form-field.mat-form-field-type-mat-chip-list.mat-form-field-appearance-fill {
  .mat-chip-list-wrapper {
    margin: 0 -4px -4px;
  }

  mat-basic-chip {
    margin-bottom: 4px !important;
  }
}

html
  body
  .app--theme__page-stepper--left-panel
  .app--pseudo-validation-stepper__header
  .mat-horizontal-stepper-header[aria-selected="true"] {
  background-color: rgba(255, 255, 255, 0.35);
}

html
  body
  .app--theme__page-stepper--container
  .mat-horizontal-content-container {
  padding: 0 !important;
}

// mat-dialog
html body .mat-dialog-container {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  transform: translate3d(0, 0, 0);
  box-shadow: none;

  .mat-dialog-title {
    background: transparent;
    color: #1e88e5;
  }

  .mat-dialog-actions {
    border: none;
  }
}

html body .cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
  // backdrop-filter: blur(2px);
}

html body .mat-paginator,
html body .mat-table {
  background: rgba(255, 255, 255, 0.55) !important;

  th {
    background: #fff;
  }
}

app-eligibility-group-card .eg,
app-company-rule-card .eg--company-rule,
app-health-plan-card .health-plan-card {
  max-width: 100% !important;
  border: 1px solid #d4e4f3 !important;
  border-radius: 10px;
  overflow: hidden;
}

html body app-eligibility-group-card {
  .health-plan-card,
  .eg--company-rule {
    background: rgba(222, 237, 251, 0.65) !important;
  }
}

html body app-health-plan-card {
  .health-plan-card__period__container {
    background: rgba(222, 237, 251, 0.65) !important;
  }
}

html body .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background: rgba(0, 0, 0, 0.62);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

html body .mat-menu-panel,
html body .mat-select-panel {
  box-shadow: none !important;
}

html body .mat-sort-header-button {
  border: none !important;
}

th {
  mat-form-field {
    margin-top: 13.5px;
  }

  mat-checkbox {
    label {
      margin: 0;
    }
  }
}

html body mat-paginator {
  .mat-form-field-infix {
    border-width: 1.5px;
  }

  .mat-form-field-appearance-fill {
    mat-select {
      .mat-select-arrow-wrapper {
        transform: translateY(-10%);
      }
    }
  }
}

// ng-scroll override
html body .ng-scroll-content {
  display: block;
}

.tiny-chip {
  border-radius: 4px !important;
  min-height: 22px !important;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 22px;
}

html body .cursor-pointer {
  cursor: pointer;
}

.app--divider {
  &__vertical {
    height: 100%;
    width: 0;
    border-left: 1px dashed #1976d2;
    margin: 0 16px;
  }

  &__horizontal {
    height: 0;
    width: 100%;
    border-bottom: 1px dashed #1976d2;
    margin: 16px 0;
  }
}
