/* You can add global styles to this file, and also import other style files */
@import "~font-awesome/css/font-awesome.css";
@import "../../shared/css/shared.css";

.client-list .mat-focused .mat-form-field-label {
  color: #fff;
}

.client-list .mat-form-field-label {
  color: rgba(255, 255, 255, .5);
}

.client-list .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, .5);
}

.client-list .mat-focused .mat-form-field-ripple {
  background-color: #fff;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background: #808080;
}

table {
  width: 100%;
}

.selected-count {
  text-transform: uppercase;
}

.selected-count div {
  padding: 3px;
}

.selected-count div:first-child {
  color: #db2828;
}

.no-data {
  display: block;
  text-align: center;
  padding: 20px;
  width: 100%;
}

h2 a {
  color: #3f3f3f;
}

.column-list .mat-checkbox-layout {
  display: block !important;
}

app-employees .mat-elevation-z8 .mat-checkbox-layout {
  margin-bottom: 0;
  padding: 4px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.mat-header-cell {
  font-size: 14px;
  font-weight: bold;
}

.edit-page-fixed {
  position: fixed;
  height: 100%;
  background: #fff;
}

.full-width {
  width: 100%;
}

.tiny-chip {
  border-radius: 4px !important;
  min-height: 22px !important;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 22px;
}
