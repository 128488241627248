mat-form-field.app__theme--field-warning .mat-form-field-flex {
  background: #ffc866 !important;
}

mat-form-field.app__theme--field-error .mat-form-field-flex {
  background: #f39595 !important;
}

mat-checkbox.app__theme--field-warning {
  .mat-checkbox-inner-container::before {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 18px);
    top: calc(50% - 18px);
    height: 36px;
    width: 36px;
    pointer-events: none;
    border-radius: 50%;
    background-color: #ffc866;
  }
}

mat-error.app--theme__mat-error-validation {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
