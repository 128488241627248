html body .app--theme__mat-button-link.mat-button,
html body .app--theme__mat-menu-link.mat-menu-item {
  text-decoration: none;
  outline: none;

  &.active:not([disabled]) {
    color: app-color(primary, 600);

    &::after {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.1;
      background: app-color(primary, 600);
      display: block;
      position: absolute;
    }
  }

  .mat-button-wrapper span {
    margin-left: 4px;
  }
}

.app--theme__button-text {
  &--left {
    margin-right: 4px;
  }

  &--right {
    margin-left: 4px;
  }
}

.app--theme__mat-icon-rounded {
  border-radius: 50%;
  font-size: 16px;

  &.primary {
    background: app-color(primary, 600);
    color: #fff;
  }

  &.light {
    background: #fff;
    color: app-color(accent, 700);
  }
}

.app--prevent-double-click {
  pointer-events: none;
}

.app--theme__data-success-button:not(.mat-button-disabled) {
  color: app-color(common, green-success) !important;
}

.app--theme__data-disabled-button {
  pointer-events: none;
  color: app-color(common, gray-disabled) !important;
}

.app--theme__data-locked-button .mat-icon {
  color: #ff8e3a !important;
}

.app--theme__data-unlocked-button .mat-icon {
  color: #94989c !important;
}

.app--theme__data-warning-button:not(.mat-button-disabled),
.app--theme__data-warning-button:not(.mat-button-disabled) .mat-icon,
.app--theme__data-warning-icon {
  color: app-color(common, orange-warnings) !important;
}

.app--theme__data-error-button:not(.mat-button-disabled) .mat-icon,
.app--theme__data-error-icon {
  color: app-color(common, red-error) !important;
}

.app--theme__irs-error-button:not(.mat-button-disabled) .mat-icon,
.app--theme__irs-error-icon {
  color: #fff;
}

.app--theme__field-suffix-container {
  &.smaller {
    display: flex;
    flex-direction: column !important;
    right: -6px;
    position: absolute;
    top: -31px;

    .app--theme__data-error-button,
    .app--theme__data-warning-button,
    .app--theme__data-lock-button {
      font-size: 10px !important;
      height: 21px !important;
      width: 21px !important;

      ::ng-deep .mat-button-wrapper mat-icon {
        line-height: 21px;
        font-size: 21px;
        width: 21px;
        height: 21px;
      }
    }
  }
}

html body .mat-button-base.app--theme__button__smaller {
  font-size: 12px;
  line-height: 28px;
  padding: 0 12px;

  .mat-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
  }
}

html body .mat-icon-button.app--theme__button__smaller {
  line-height: 28px;
  height: 28px;
  width: 28px;
  padding: 0;

  .mat-icon {
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 20px;
  }
}

html body .app--cache-status-snackbar {
  margin-top: 72px;
}

html body .app--cache-status-snackbar .mat-button {
  color: app-color(primary, 600);
}

html body .app--suffix-button {
  &:not(.shown) {
    opacity: 0;
    pointer-events: none;
  }
}

html body .app--theme__mat-button-menu-default-option {
  display: inline-block;

  &.disabled,
  &.disabled > :nth-child(2) {
    pointer-events: none;
  }

  & > :nth-child(2) {
    border-radius: 0 4px 4px 0;
    min-width: 0;
  }

  & > :nth-child(1) {
    border-radius: 4px 0 0 4px;

    &:not(.mat-button-disabled) {
      pointer-events: all !important;
    }
  }

  &.separator {
    & > :nth-child(2) {
      border-left: 1px solid #fff;
    }
  }
}
