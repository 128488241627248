@use "@angular/material" as mat;
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat-core();

@import "settings/palettes";
@import "projects/shared/styles/settings/mixins";
@import "swiper/swiper-bundle";

$custom-typography: app-typography-config();

@include mat.all-component-typographies($custom-typography);

@include mat.core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bencorp-app-primary: mat.define-palette(
  $bencorp-palette-primary,
  600,
  300,
  900
);
$bencorp-app-accent: mat.define-palette(
  $bencorp-palette-secondary,
  800,
  600,
  900
);

// The warn palette is optional (defaults to red).
$bencorp-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$bencorp-app-theme: mat.define-light-theme(
  $bencorp-app-primary,
  $bencorp-app-accent,
  $bencorp-app-warn
);
$bencorp-app-theme-dark: mat.define-dark-theme(
  $bencorp-app-primary,
  $bencorp-app-accent,
  $bencorp-app-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bencorp-app-theme);

html body *.app--theme-dark {
  @include mat.all-component-themes($bencorp-app-theme-dark);
}

@import "index";

button:focus {
  outline: none !important;
}

table {
  border-collapse: separate;
  width: 100%;
}

html body .app--elevation {
  @include app--elevation();
}

html body .app--elevation-z1 {
  @include app--elevation-z1();
}

* {
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #b6babd !important;
  }
}

html body ng-progress .ng-spinner {
  display: none !important;
}

* {
  box-sizing: border-box;
}
