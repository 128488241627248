html body .app--mat-checkbox {
  &__check-less {
    .mat-checkbox-inner-container {
      display: none;
    }

    &:not(.mat-checkbox-checked) .mat-checkbox-label mat-icon {
      color: #c2cfdb !important;
    }
  }
}
