.app--theme__list-option {
  &--selection {
    border-bottom: #eeeeee 1px solid;

    &[aria-selected="true"],
    &.mat-radio-checked {
      background: app-color(primary, 100);
      color: app-color-text(primary, 100);
    }

    .mat-pseudo-checkbox {
      display: none;
    }

    .mat-list-text {
      padding: 0 !important;
    }
  }
}
