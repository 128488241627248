.issues-badge {
  height: 20px;
  border-radius: 10px;
  display: inline-block;
  padding: 0 10px;
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  text-align: center;
  margin-right: 8px;
  opacity: 0;
  vertical-align: middle;

  &.visible {
    opacity: 1;
  }

  &.errors {
    background: app-color(common, red-error);
  }

  &.warnings {
    background: app-color(common, orange-warnings);
  }

  &.conflicts {
    background: app-color(common, red-error);

    &.resolved {
      background: app-color(common, green-success);
    }
  }
}
