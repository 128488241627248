html body .app--theme__tooltip {
  &--primary {
    white-space: pre-wrap;

    &.break {
      word-break: break-all;
    }
  }

  &__data-warning {
    background-color: #ff8e3a;
    opacity: 1;
    font-size: 12px;
    color: #000 !important;
    white-space: pre-line;
  }

  &__data-error {
    background-color: #ff6347;
    opacity: 1;
    font-size: 12px;
    color: #000 !important;
    white-space: pre-line;
  }

  &__irs-error {
    background-color: #450f0b;
    opacity: 1;
    font-size: 12px;
    color: #fff !important;
    white-space: pre-line;
  }

  &--full {
    overflow: visible;
    white-space: pre-wrap;
    word-break: break-all;
  }

  // Workaround for safari as it has native titles enabled by default for all overflowed texts.
  &--disable-native {
    &::after {
      content: "";
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--mat-error {
    background-color: #f44336;
  }
}

.mat-error {
  font-size: 12px;

  &.after {
    margin-top: -18px;
    z-index: 1;
  }
}

.app--skeleton:empty {
  background-color: #dddee1;
  border-radius: 3px;
}

html body .mat-tooltip {
  &.app__theme--field-error {
    background: #f17373;
    white-space: pre-line;
  }

  &.app__theme--field-warning {
    background: #f8b746;
    white-space: pre-line;
  }

  &.app__theme--field-multiline {
    white-space: pre-line;
  }
}
