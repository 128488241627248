html body .app--theme__toast-snack {
  white-space: pre-line;

  &--success {
    background: #148c00;
  }

  &--error {
    background: #e24c13;
  }

  &--info {
    background: #6c3b00;
  }
}

html body .mat-snack-bar-container {
  margin: 70px 4px 0 0;
}
