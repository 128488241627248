@use "@angular/material" as mat;
@use "sass:math";

.app--theme__header {
  @include mat.elevation(6);
  background: #fff;
  height: $app-header-height;
  z-index: 10;
  min-height: 0;
  position: absolute;
  padding: 0 24px;
  display: flex;
  justify-content: flex-start;
  color: app-color-text(accent, 50);
}

.app--theme__hamburger {
  height: 36px;
  width: 36px;
  border-radius: 4px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  padding: 0 8px !important;
  min-width: 0 !important;
  position: relative;

  &--ripple {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &--container {
    $height: 16px;
    $bar-heigh: 3px;
    $gap: math.div(($height - 3 * $bar-heigh), 2);
    $opened-gap: math.div(($height - $bar-heigh), 2);

    width: 22px;
    height: $height;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 1;
    display: inline-block;

    span {
      display: block;
      position: absolute;
      height: $bar-heigh;
      width: 100%;
      background: app-color(primary, 600);
      border-radius: 3px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2) {
      top: $bar-heigh + $gap;
    }

    span:nth-child(3) {
      top: 2 * ($bar-heigh + $gap);
    }

    &.open span:nth-child(1) {
      top: $opened-gap;
      transform: rotate(135deg);
    }

    &.open span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    &.open span:nth-child(3) {
      top: $opened-gap;
      transform: rotate(-135deg);
    }
  }
}
