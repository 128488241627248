@use "@angular/material" as mat;
$left-panel-width: 300px;
$left-panel-padding: 16px 24px 0;
$container-padding: 0 8px;
$left-panel-border: 1px solid rgba(34, 36, 38, 0.15);
$toolbar-height: 64px;

.app--theme__page {
  &--subheader {
    // background: app-color(primary, 800);
    // color: app-color-text(primary, 800);
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 24px;
  }

  &--title {
    display: flex;
    align-items: center;
  }

  &--container {
    padding: 24px;
  }

  &--wider,
  &--wide {
    $subheaderHeight: 92px;
    margin: 0 auto;
    width: 100%;
    max-width: unset;
    padding: 0 24px;

    &__subheader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 0;
    }

    &__content {
      $padding-bottom: 24px;

      &.toolbar-bottom {
        padding-bottom: $padding-bottom + $toolbar-height;
      }

      &.fixed {
        display: flex;
        flex-direction: column;
        height: calc(100vh - #{$subheaderHeight} - #{$toolbar-height});

        & > * {
          min-height: 0;
        }
      }
    }

    &__title {
      margin: 0;
      margin-left: auto;
      color: #4d7292;
      font-weight: 900;
    }

    &__subheader-actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &--wider {
    $help-gap: 72px;
    padding: 0 $help-gap;
  }
}

.app--theme__page-table {
  display: flex;
  height: 100%;

  &.toolbar-bottom {
    padding-bottom: $toolbar-height;
  }

  &--container {
    flex: 1;
    padding: $container-padding;
    overflow-y: auto;

    .mat-stepper-horizontal {
      margin: 0 -24px;
    }
  }
}

.app--theme__page-stepper {
  display: flex;
  padding: 0 0 4px 24px;
  height: 100%;

  &.toolbar-bottom {
    padding-bottom: $toolbar-height;
  }

  &--left-panel {
    padding: $left-panel-padding;
    width: $left-panel-width;
    border-right: $left-panel-border;

    .app--pseudo-validation-stepper__header {
      margin: 0 -24px 16px;
      .mat-horizontal-stepper-header[aria-selected="true"] {
        background-color: #cceeff;
      }
    }
  }

  &--left-panel__title {
    min-height: 48px;
    max-height: 48px;
  }

  &--left-panel-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .app--pseudo-validation-stepper__header {
      border-bottom: 1px dashed app-color(primary, 700);
    }

    & > .mat-title {
      margin: 12px 0;
    }
  }

  &--left-panel-actions {
    position: absolute;
    top: -12px;
    right: -20px;
    height: 28px;
    z-index: 1;
    display: flex;

    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }

  &--container {
    flex: 1;
    padding: 0 0 0 8px;
    overflow-y: auto;
    background: #fff;

    .mat-stepper-horizontal {
      margin: 0 -8px;
    }

    .mat-horizontal-content-container {
      padding: 12px !important;
    }
  }
}

.app--theme__bottom-panel {
  @include mat.elevation(10);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: $toolbar-height;
  background: #fff;
  display: flex;

  &--wr {
    position: relative;
    height: $toolbar-height;
    width: 100%;
  }

  &--left-panel {
    width: $left-panel-width;
    padding: $left-panel-padding;
    border-right: $left-panel-border;
  }

  &--container {
    flex: 1;
    display: flex;
    align-items: center;
    padding: $container-padding;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.app--form-group {
  padding: 12px 12px 0 12px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 12px;
}

.app--form-group-title {
  color: app-color(primary, 600);
  margin-bottom: 24px;
}

.app--tab-container {
  &,
  & > * {
    min-width: 0;
  }
}

.app--theme__scroll {
  overflow-y: auto;
}

.app--theme__page-stepper--left-panel {
  transition: width 0.25s ease-in-out;

  .mat-slide-toggle.is-active {
    ::ng-deep {
      .mat-slide-toggle-content {
        transition: all 0.25s ease-in-out;
        width: 100%;
      }
    }
  }

  &.minimized {
    width: 78px;

    .mat-slide-toggle.is-active {
      ::ng-deep {
        .mat-slide-toggle-content {
          width: 0;
        }
      }
    }
  }
}

.app--theme__bottom-panel:not(.full-width) {
  transition: padding 0.25s ease-in-out;

  &.extended {
    padding-left: 94px;
    padding-right: 262px;
  }
}
