@use "@angular/material" as mat;
@import "palettes";

@function app-color($palette, $a) {
  @return mat.get-color-from-palette(map-get($palettes, $palette), $a);
}

@function app-color-text($palette, $a) {
  @return mat.get-color-from-palette(
    map-get(map-get($palettes, $palette), contrast),
    $a
  );
}

@mixin app-text-truncate($lines: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.app--text-truncate {
  @include app-text-truncate();
}

.app--text-truncate-table {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  max-width: 150px;
}

.app--aspect-ratio {
  width: 100%;
  position: relative;

  &-fixed-height {
    @media only screen and (min-width: 425px) and (max-width: 954px) {
      height: 344px;
      padding-top: 0 !important;
    }
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    & > * {
      height: 100%;
      width: 100%;
      display: block;
    }
  }
}

.app--min-width-0 {
  min-width: 0;
}

$custom-typography: mat.define-typography-config(
  $font-family: "Roboto, monospace",
  $display-4: mat.define-typography-level(112px, 112px, 300),
  $display-3: mat.define-typography-level(56px, 56px, 400),
  $display-2: mat.define-typography-level(45px, 48px, 400),
  $display-1: mat.define-typography-level(34px, 40px, 400),
  $headline: mat.define-typography-level(28px, 34px, 400),
  $title: mat.define-typography-level(20px, 32px, 600),
  $subheading-2: mat.define-typography-level(16px, 28px, 400),
  $subheading-1: mat.define-typography-level(15px, 24px, 400),
  $body-2: mat.define-typography-level(14px, 24px, 600),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 600),
  $input: mat.define-typography-level(inherit, 1.125, 400),
);

@function app-typography-config() {
  @return $custom-typography;
}

@mixin app--elevation() {
  box-shadow: 0px -1px 38px -20px rgba(65, 86, 107, 1) !important;
  box-shadow: 0px -1px 26px -16px #41566b;
}

@mixin app--elevation-z1() {
  box-shadow: 0px 4px 38px -24px #41566b,
    0px 4px 16px -4px rgba(65, 86, 107, 0.38) !important;
}
