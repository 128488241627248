body {
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.break-all {
  word-break: break-all;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.d-table-row {
  display: table-row !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

input,
button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

h5 {
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
  margin-top: 0;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

table {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.position-relative {
  position: relative !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  &-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #00000008;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    &:first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    }
  }

  &-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }

  @media (min-width: 576px) {
    &-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;

      .card {
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
      }
    }
  }
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.blockquote {
  margin: 0 0 1rem;
  font-size: 1.25rem;

  &-footer {
    display: block;
    font-size: 0.875em;
    color: #6c757d;
    &:before {
      content: "\2014\a0";
    }
  }
}

.float-right {
  float: right !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;

    &:hover {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
    }

    &:focus {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
      box-shadow: 0 0 0 0.2rem #268fff80;
    }
  }

  &-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}

button {
  text-transform: none;
  overflow: visible;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

[hidden] {
  display: none !important;
}

code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace;
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
