html body .mat-chip.app--mat-chip {
  &.warn {
    background: #ffa726 !important;
  }
}

.app--badge-fill {
}
html body mat-chip.mat-standard-chip.app--chip-tiny {
  font-size: 12px;
  font-weight: normal;
  padding: 0px 1px 0px 4px;
  height: 18px;
  line-height: 22px;
  border-radius: 11px;
  min-height: 0;

  mat-icon {
    margin-left: 2px;
  }
}
